import React, { useState, useEffect } from 'react';
import axios from './axiosConfig'; // 确保导入了配置好的 axios 实例
import './App.css';

const MyComponent = () => {
    const [data, setData] = useState([]);
    const [pluginId, setPluginId] = useState('中转服务');
    const [validDays, setValidDays] = useState(31);
    const [count, setCount] = useState(1);
    const [deviceId, setDeviceId] = useState('');
    const [checkData, setCheckData] = useState([]);
    const [message, setMessage] = useState('');
    const [password, setPassword] = useState(localStorage.getItem('password') || ''); // 从 localStorage 获取密码
    const [accountCount, setAccountCount] = useState(2);
    const [expireTime, setExpireTime] = useState('');

    useEffect(() => {
        localStorage.setItem('password', password); // 存储密码到 localStorage
    }, [password]);

    // 获取授权码
    const fetchData = async () => {
        try {
            const response = await axios.get('/getKeys', { params: { password } });
            setData(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    // 添加授权码
    const addAuthorizationCode = async () => {
        try {
            await axios.get('/add', { params: { count, validDays, plugin_id: pluginId, accountCount, password } });
            setMessage('成功');
        } catch (error) {
            setMessage(error.response?.data || '请求失败');
        }
    };
    // 修改授权
    const modifyAuthorizationCode = async () => {
        try {
            await axios.get('/modify', { params: { plugin_id: pluginId, device_id: deviceId, expireTime: new Date(expireTime).getTime(), accountCount, password } });
            setMessage('成功');
        } catch (error) {
            setMessage(error.response?.data || '请求失败');
        }
    };


    // 检查设备
    const checkDevice = async () => {
        try {
            const response = await axios.get('/check', { params: { device_id: deviceId } });
            setCheckData(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="App">
            <div className="input-group">
                <h3>输入密码</h3>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="输入密码"
                />
            </div>

            <div className="section">
                <h3>获取授权码</h3>
                <button className="fetch-button" onClick={fetchData}>获取授权码</button>
                <div className="table-container">
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th>AuthCode</th>
                                <th>PluginId</th>
                                <th>ValidDays</th>
                                <th>AccountCount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.AuthCode}</td>
                                    <td>{item.PluginId}</td>
                                    <td>{item.ValidDays}</td>
                                    <td>{item.AccountCount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="section">
                <h3>添加授权码</h3>
                <select
                    value={pluginId}
                    onChange={(e) => setPluginId(e.target.value)}
                    className="select-input"
                >
                    <option value="中转服务">中转服务</option>
                    <option value="交易提醒">交易提醒</option>
                    <option value="委托跟单">委托跟单</option>
                    <option value="锁仓解锁">锁仓解锁</option>
                    <option value="持仓跟仓">持仓跟仓</option>
                    <option value="浮动止损">浮动止损</option>
                    <option value="定点止损">定点止损</option>
                </select>
                <h4>有效天数</h4>
                <input className='select-input'
                    type="number"
                    value={validDays}
                    onChange={(e) => setValidDays(e.target.value)}
                    placeholder="ValidDays"
                />
                <h4>添加授权码数量</h4>
                <input className='select-input'
                    type="number"
                    value={count}
                    onChange={(e) => setCount(e.target.value)}
                    placeholder="添加授权码数量"
                />
                <h4>账号数量</h4>
                <input className='select-input'
                    type="number"
                    value={accountCount}
                    onChange={(e) => setAccountCount(e.target.value)}
                    placeholder="账号数量"
                />
                <button className="command-button" onClick={addAuthorizationCode}>添加授权码</button>
                <p className="message">{message}</p>
            </div>



            <div className="section">
                <h3>检查设备</h3>
                <input
                    type="text" className='select-input'
                    value={deviceId}
                    onChange={(e) => setDeviceId(e.target.value)}
                    placeholder="DeviceId"
                />
                <button className="check-button" onClick={checkDevice}>检查</button>
                <div className="table-container">
                    <table className="check-table">
                        <thead>
                            <tr>
                                <th>PluginId</th>
                                <th>ExpireTime</th>
                                <th>AccountCount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {checkData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.PluginId}</td>
                                    <td>{new Date(item.ExpireTime).toISOString()}</td>
                                    <td>{item.AccountCount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div className="section">
                <h3>修改授权</h3>
                <h4>DeviceId</h4>
                <input className='select-input'
                    type="text"
                    value={deviceId}
                    onChange={(e) => setDeviceId(e.target.value)}
                    placeholder="DeviceId"
                />
                <h4>PluginId</h4>
                <select
                    value={pluginId}
                    onChange={(e) => setPluginId(e.target.value)}
                    className="select-input"
                >
                    <option value="中转服务">中转服务</option>
                    <option value="交易提醒">交易提醒</option>
                    <option value="委托跟单">委托跟单</option>
                    <option value="锁仓解锁">锁仓解锁</option>
                    <option value="持仓跟仓">持仓跟仓</option>
                    <option value="浮动止损">浮动止损</option>
                    <option value="定点止损">定点止损</option>
                </select>
                <h4>到期日</h4>
                <input className='select-input'
                    type="date"
                    value={expireTime}
                    onChange={(e) => setExpireTime(e.target.value)}
                    placeholder="到期日"
                />
                <h4>账号数量</h4>
                <input className='select-input'
                    type="number"
                    value={accountCount}
                    onChange={(e) => setAccountCount(e.target.value)}
                    placeholder="账号数量"
                />
                <button className="command-button" onClick={modifyAuthorizationCode}>修改授权</button>
                <p className="message">{message}</p>
            </div>
        </div>
    );
};

export default MyComponent;
