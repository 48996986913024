import './App.css';
import MyComponent from './MyComponents';

function App() {
  return (
    <div className="App">
      <MyComponent />
    </div>
  );
}

export default App;
